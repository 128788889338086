<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Usuários</h1>
            </div>
            <div class="row">
              <div class="sixteen column wide">
                <div class="searchbar">
                  <div class="icon search">
                    <img src="@/assets/icons/search.svg" />
                  </div>
                  <input
                    type="text"
                    v-model="searching.value"
                    placeholder="Pesquisar"
                  />
                  <div
                    v-if="searching.value"
                    class="icon clear"
                    @click="clearSearch()"
                  >
                    <img src="@/assets/icons/close.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="sixteen column wide">
                <table class="ui fixed single line table dimmable">
                  <div class="ui active inverted dimmer" v-if="loading">
                    <div class="ui loader"></div>
                  </div>
                  <thead>
                    <tr>
                      <th class="four wide left aligned"><h3>Nome</h3></th>
                      <th class="six wide left aligned"><h3>Email</h3></th>
                      <th class="six wide left aligned"><h3>Ações</h3></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="user in users"
                      :key="user.id"
                      class="user__list"
                      :class="{ admin: user.admin }"
                    >
                      <td>
                        <h4 class="ui image header item">
                          <div class="profile">
                            <router-link
                              :is="user.admin ? 'router-link' : 'span'"
                              :to="user.admin && `/backoffice/users/${user.id}`"
                            >
                              <img :src="gravatar(user.email)" />
                            </router-link>
                          </div>
                          <div class="content">
                            <router-link
                              class="item__link-text"
                              :is="user.admin ? 'router-link' : 'span'"
                              :to="user.admin && `/backoffice/users/${user.id}`"
                            >
                              {{ user.firstName }}
                              {{ user.lastName }}
                            </router-link>
                          </div>
                        </h4>
                      </td>
                      <td class="aligned">
                        <h4>
                          <router-link
                            class="item__link-text"
                            :is="user.admin ? 'router-link' : 'span'"
                            :to="user.admin && `/backoffice/users/${user.id}`"
                          >
                            {{ user.email }}
                          </router-link>
                        </h4>
                      </td>
                      <td class="actions">
                        <router-link
                          class="item__link-text"
                          :is="user.admin ? 'router-link' : 'span'"
                          :to="user.admin && `/backoffice/users/${user.id}`"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"
                            />
                          </svg>
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                  <tbody class="no-data" v-if="!users.length">
                    <tr>
                      <td colspan="6">
                        <h2>Não encontramos nada relacionado</h2>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <Pagination
                :pages="pageCount"
                :count="payload.pagination.page"
                :clicked="paginate"
                v-if="users.length"
              >
              </Pagination>
            </div>
          </div>
        </div>
      </main>
    </div>
  </Backoffice>
</template>

<script>
import md5 from "md5";
import _ from "lodash";
import Backoffice from "../../Template/Backoffice";
import Pagination from "../../../components/Pagination";
import { emailToken } from "../../../utils/index";

export default {
  name: "Users",
  components: {
    Backoffice,
    Pagination
  },
  watch: {
    "searching.value": function() {
      this.searching.writing = true;
      this.debouncedSearch();
    }
  },
  data() {
    return {
      loading: false,
      users: [],
      noPhoto: "",
      searching: {
        value: "",
        minLength: 3,
        writing: false
      },
      pageCount: 1,
      lastPageCount: 1,
      payload: {
        pagination: {
          page: 1,
          paginate: 5
        }
      },
      filters: {
        filter: {
          firstName: ""
        }
      }
    };
  },
  computed: {},
  methods: {
    getUserList(payload) {
      let vm = this;
      vm.loading = true;

      let data = payload;

      vm.$store.dispatch("User/GET_USERS_LIST", data).then(_response => {
        vm.pageCount = _response.pages;
        vm.verifyAdmin(_response.list);
        vm.loading = false;
      });
    },
    search() {
      let vm = this;
      vm.searching.writing = false;
      if (
        vm.searching.value.length >= vm.searching.minLength ||
        vm.searching.value.length == 0
      ) {
        vm.filters.filter.firstName = vm.searching.value;
        vm.filters.filter.firstName == ""
          ? vm.getUserList(vm.payload)
          : vm.getUserList(vm.filters);
      }
    },
    clearSearch() {
      let vm = this;
      if (vm.searching.value) {
        vm.searching.value = "";
        vm.filters.filter.firstName = "";
        vm.getUserList(vm.payload);
      }
    },
    toggleUserProfileSideBar(userId) {
      const vm = this;
      vm.loading = true;

      vm.$store.dispatch("User/GET_USER_BY_ID", userId).then(_response => {
        vm.$bus.$emit("sidebar", { type: "profile", data: _response });
        vm.loading = false;
      });
    },
    gravatar(email) {
      let emailHash = md5(email);
      return `https://secure.gravatar.com/avatar/${emailHash}?size=100&d=mp`;
    },
    paginate(pageCount) {
      let vm = this;
      vm.payload.pagination.page = pageCount;
      vm.getUserList(vm.payload);
    },
    verifyAdmin(users) {
      let vm = this;
      let email = emailToken(localStorage.token);

      const usersArray = users.map(i => {
        let adminUser = i.email == email ? true : false;

        let user = Object.assign(i, { admin: adminUser });

        return user;
      });

      vm.users = usersArray;
    }
  },
  beforeCreate() {},
  created() {
    let vm = this;
    vm.getUserList(vm.payload);
    vm.debouncedSearch = _.debounce(vm.search, 500);
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/globals/buttons";

h2 {
  font-family: Barlow;
  font-size: 16.5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  text-align: right;
  color: #757575;
  margin: 0;
  padding-left: 0.5rem;
}
.location {
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
.avatar {
  width: 48px !important;
  height: 48px !important;
  border-radius: 2px !important;
  background-color: #ccc;
}
.searchbar {
  position: relative;
  width: 100%;
  height: 64px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
  outline: none;
  input {
    line-height: 2rem;
    font-size: 1.4rem;
    padding: 1rem 64px;
    width: 100%;
    height: 100%;
    border: none;
    background: transparent;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    &.search {
      left: 0;
    }
    &.clear {
      right: 0;
      cursor: pointer;
      img {
        width: 24px;
        height: auto;
      }
    }
    img {
      -webkit-tap-highlight-color: transparent;
      &::selection {
        background: transparent;
      }
    }
  }
}
table {
  thead {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  tbody {
    &.no-data {
      tr {
        cursor: default;
      }
      h2 {
        text-align: center;
      }
    }
    tr {
      transition: 200ms;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
.capitalize {
  text-transform: lowercase;
}
.capitalize:first-letter {
  text-transform: uppercase;
}

.user__list {
  opacity: 0.7;

  &.admin {
    opacity: 1;
  }
  .item {
    display: flex;
    align-items: center;

    .profile {
      width: 35px;
      height: 35px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 8px;

      img {
        max-width: 100%;
      }
    }
  }

  .item__link-text {
    color: $color-primary;
  }
  .actions {
    a {
      display: flex;
      align-items: center;
    }
    svg {
      max-width: 20px;
      height: 20px;
      fill: $color-secondary;
    }
  }
}
</style>
