<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Relatórios</h1>
            </div>
            <div class="row">
              <div class="sixteen wide column">
                <form class="ui form" @submit.prevent="submitReportRequest">
                  <div class="field" :class="{ error: $v.form.email.$error }">
                    <label>Email cliente (opcional)</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Email do cliente"
                      v-model.trim="$v.form.email.$model"
                    />
                  </div>
                  <div
                    class="field"
                    :class="{ error: $v.form.statusCurrent.$error }"
                  >
                    <label>Status da Assinatura (opcional)</label>
                    <div class="ui selection select__status dropdown">
                      <input
                        type="hidden"
                        name="status"
                        v-model="$v.form.statusCurrent.$model"
                      />
                      <i class="dropdown icon"></i>
                      <div class="default text">Status</div>
                      <div class="menu">
                        <div class="item" data-value="">STATUS</div>
                        <div class="item" data-value="ACTIVE">ACTIVE</div>
                        <div class="item" data-value="PAUSED">PAUSED</div>
                        <div class="item" data-value="CANCELED">CANCELED</div>
                        <div class="item" data-value="EXPIRED">EXPIRED</div>
                      </div>
                    </div>
                  </div>
                  <div class="field">
                    <label>Escolha um intervalo de datas (obrigatório)</label>
                    <functional-calendar
                      class="report__date"
                      :configs="calendarConfigs"
                      v-model="calendarData"
                      ref="reportCalendar"
                    >
                    </functional-calendar>
                  </div>
                  <button
                    class="ui button button__primary"
                    :class="{ loading: sendingForm == true }"
                    type="submit"
                    :disabled="sendingForm"
                  >
                    Solicitar relatório
                  </button>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="sixteen wide column">
                <div class="report__success-wrapper" v-if="report.saved">
                  <h3>O Relatório foi gerado com sucesso!</h3>
                  <p>Você deseja receber por email ou baixar agora?</p>
                  <div class="ui buttons">
                    <button
                      class="ui button"
                      :class="{
                        loading: report.sendToEmailLoading,
                        disabled:
                          report.sendToEmailLoading ||
                          report.downloadReportLoading,
                      }"
                      @click="sendReportToEmail"
                    >
                      Enviar para email
                    </button>
                    <div class="or"></div>
                    <button
                      class="ui positive button"
                      :class="{
                        'loading disabled': report.downloadReportLoading,
                        disabled:
                          report.sendToEmailLoading ||
                          report.downloadReportLoading,
                      }"
                      @click="downloadReport"
                    >
                      Baixar agora
                    </button>
                  </div>
                </div>

                <div
                  class="report__success-email-sended-wrapper"
                  v-if="report.emailSended"
                >
                  <h3>
                    O Relatório foi enviado com sucesso para {{ adminEmail }}!
                  </h3>
                  <button class="ui button red" @click="closeMessage">
                    Fechar ({{ counterToCloseMessage }})
                  </button>
                </div>

                <div
                  class="report__success-downloaded-wrapper"
                  v-if="report.reportDownloaded"
                >
                  <h3>O Relatório foi baixado com sucesso!</h3>
                  <button class="ui button red" @click="closeMessage">
                    Fechar ({{ counterToCloseMessage }})
                  </button>
                </div>

                <div
                  v-if="showFillFieldsMessage"
                  class="error__message ui red horizontal label"
                >
                  <p>Por favor, preencha todos os campos!</p>
                </div>
                <div v-if="translatedMessage" class="ui horizontal label">
                  <p>{{ translatedMessage }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </Backoffice>
</template>

<script>
import Backoffice from '../../Template/Backoffice';
import { FunctionalCalendar } from 'vue-functional-calendar';
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  name: 'Reports',
  components: {
    Backoffice,
    FunctionalCalendar,
  },
  mixins: [validationMixin],
  data() {
    return {
      loading: false,
      messageServer: '',
      messageTranslated: '',
      showFillFieldsMessage: false,
      form: {
        email: '',
        statusCurrent: '',
      },
      report: {
        saved: false,
        name: '',
        linkToDownloadReport: '',
        sendToEmailLoading: false,
        downloadReportLoading: false,
        emailSended: false,
        reportDownloaded: false,
      },
      calendarData: {
        dateRange: {
          end: this.$moment(new Date())
            .subtract(1, "days")
            .format("DD/MM/YYYY"),
          start: this.$moment(new Date())
            .subtract(31, "days")
            .format("DD/MM/YYYY")
        }
      },
      calendarConfigs: {
        sundayStart: false,
        dateFormat: 'dd/mm/yyyy',
        isDatePicker: false,
        isDateRange: true,
        isModal: true,
      },
      sendingForm: false,
      counterToCloseMessage: 10,
    };
  },
  computed: {
    translatedMessage: function () {
      if (this.messageServer !== '') {
        switch (this.messageServer) {
          case 'No result for the search':
            this.messageTranslated = 'Nenhum resultado encontrado';
            return 'Nenhum resultado encontrado';
            break;
          default:
            return '';
            break;
        }
      } else {
        return '';
      }
    },
    adminEmail: function () {
      return this.$store.getters['User/getAdminInfo'].email;
    },
  },
  methods: {
    resetReportForm() {
      const vm = this;
      vm.form.email = '';
      vm.form.statusCurrent = '';
      $('.select__status').dropdown('set selected', '');
      vm.$v.$reset();
    },
    submitReportRequest() {
      let vm = this;
      vm.showFillFieldsMessage = false;
      if (vm.$v.$invalid) {
        vm.showFillFieldsMessage = true;
      } else {
        vm.sendingForm = true;
        vm.messageServer = '';
        let data = {
          filter: {
            email: vm.form.email,
            status: vm.form.statusCurrent,
            initial_date: this.$moment(
              vm.calendarData.dateRange.start,
              'DD/MM/YYYY'
            ).format('YYYY-MM-DD'),
            final_date: this.$moment(
              vm.calendarData.dateRange.end,
              'DD/MM/YYYY'
            ).format('YYYY-MM-DD'),
          },
        };

        data.filter.email == '' ? delete data.filter.email : null;
        data.filter.status == '' ? delete data.filter.status : null;

        vm.$store
          .dispatch('Reports/POST_REPORTS', data)
          .then((_response) => {
            vm.sendingForm = false;
            console.log('MESSAGE', _response.message);
            if (_response.message != '' && _response.message != undefined) {
              vm.messageServer = _response.message;
              setTimeout(function () {
                vm.messageServer = '';
              }, 5000);
              return;
            }

            if (_response.saved) {
              vm.report.saved = _response.saved;
              vm.report.name = _response.report_name;
              vm.report.linkToDownloadReport = _response.download;
            } else {
              console.log(
                '🛑 Houve algum problema ao salvar o relatório: ',
                _response
              );
            }

            vm.resetReportForm();
          })
          .catch((_error) => {
            console.error(_error);
          });
      }
    },
    sendReportToEmail() {
      let vm = this;
      vm.report.sendToEmailLoading = true;
      vm.sendingForm = true;

      let payload = {
        report_name: vm.report.name,
        email: vm.adminEmail,
      };
      vm.$store
        .dispatch('Reports/SEND_REPORT_TO_EMAIL', payload)
        .then((_response) => {
          vm.report.sendToEmailLoading = false;
          vm.report.saved = false;
          vm.report.emailSended = true;
          vm.counterToClose('emailSended');
        })
        .catch((_error) => {
          console.error(_error);
        });
    },
    downloadReport() {
      let vm = this;
      vm.report.downloadReportLoading = true;

      const link = document.createElement('a');
      link.href = vm.report.linkToDownloadReport;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      vm.sendingForm = true;
      vm.report.downloadReportLoading = false;
      vm.report.saved = false;
      vm.report.reportDownloaded = true;
      vm.counterToClose('reportDownloaded');
    },
    counterToClose(propertie) {
      let vm = this;
      let counter = setInterval(() => {
        if (vm.counterToCloseMessage > 0) {
          vm.counterToCloseMessage = vm.counterToCloseMessage - 1;
        } else {
          vm.sendingForm = false;
          vm.report[propertie] = false;
          clearInterval(counter);
          vm.counterToCloseMessage = 10;
        }
      }, 1000);
    },
    closeMessage() {
      let vm = this;
      vm.counterToCloseMessage = 0;
      vm.sendingForm = false;
    },
  },
  validations: {
    form: {
      email: {
        email,
      },
      statusCurrent: {},
    },
  },
  beforeCreate() {},
  created() {},
  mounted() {
    /* JS to active Select dropdown */
    const vm = this;
    $('.select__status').dropdown({
      onChange: function (value, text) {
        vm.$v.form.statusCurrent.$model = value;
      },
    });
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/globals/buttons';
.row.header {
  justify-content: space-between;
}
h2 {
  font-family: Barlow;
  font-size: 16.5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  text-align: right;
  color: #757575;
  margin: 0;
  padding-left: 0.5rem;
}
</style>

<style lang="scss">
.report__date {
  .vfc-multiple-input {
    display: flex;
    flex-direction: row;
    input {
      &:first-of-type {
        margin-right: 7.5px;
      }
      &:last-of-type {
        margin-left: 7.5px;
      }
    }
  }
}
.report__success-wrapper,
.report__success-email-sended-wrapper,
.report__success-downloaded-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
