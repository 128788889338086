<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Criar novo usuário</h1>
            </div>

            <div class="row">
              <div class="sixteen column wide">
                <div class="ui active inverted dimmer" v-if="loading">
                  <div class="ui loader"></div>
                </div>

                <form
                  class="ui form"
                  v-for="user in users"
                  :key="user.id"
                  @submit.prevent="submitUpdate"
                >
                  <div class="field">
                    <label>Nome</label>
                    <input
                      type="text"
                      name="name"
                      placeholder="Nome"
                    />
                  </div>
                  <div class="field">
                    <label>Sobrenome</label>
                    <input
                      type="text"
                      name="surname"
                      placeholder="Sobrenome"
                    />
                  </div>
                  <div class="field">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="E-mail"
                    />
                  </div>

                  <div class="field">
                    <label>Senha</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Senha"
                    />
                  </div>

                  <button class="ui button button__primary" type="submit">
                    Salvar
                  </button>
                  <button class="ui button" :disabled="message.sendForm">
                    <router-link :to="'/backoffice/users/'"
                      >Cancelar</router-link
                    >
                  </button>
                </form>
                <div v-if="translatedMessage">{{ translatedMessage }}</div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </Backoffice>
</template>

<script>
import Backoffice from "../../Template/Backoffice";

export default {
  name: "Create Users",
  components: {
    Backoffice
  },
  watch: {},
  data() {
    return {
      loading: false,
      users: [],
      password: "",
      message: {
        server: "",
        translated: "",
        sendForm: false,
        passwordMessage: false
      }
    };
  },
  computed: {},
  methods: {
    submitUpdate() {

    },
  },
  beforeCreate() {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/globals/buttons";
h2 {
  font-family: Barlow;
  font-size: 16.5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  text-align: right;
  color: #757575;
  margin: 0;
  padding-left: 0.5rem;
}
</style>
