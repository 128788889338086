<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Editar Usuário</h1>
            </div>

            <div class="row">
              <div class="sixteen column wide">
                <div class="ui active inverted dimmer" v-if="loading">
                  <div class="ui loader"></div>
                </div>

                <form
                  class="ui form"
                  v-for="user in users"
                  :key="user.id"
                  @submit.prevent="submitUpdate"
                >
                  <div class="field">
                    <label>Nome do usuário</label>
                    <input
                      type="text"
                      name="username"
                      placeholder="Username"
                      disabled="disabled"
                      v-model="user.username"
                    />
                  </div>
                  <div class="field">
                    <label>Nome</label>
                    <input type="text" name="name" placeholder="Nome" v-model="user.firstName" />
                  </div>
                  <div class="field">
                    <label>Sobrenome</label>
                    <input
                      type="text"
                      name="surname"
                      placeholder="Sobrenome"
                      v-model="user.lastName"
                    />
                  </div>
                  <div class="field">
                    <label>Email</label>
                    <input type="email" name="email" placeholder="E-mail" v-model="user.email" />
                  </div>

                  <div class="field">
                    <label>Senha</label>
                    <input type="password" name="password" placeholder="Senha" v-model="password" />
                  </div>

                  <button
                    class="ui button button__primary"
                    :disabled="message.sendForm"
                    type="submit"
                  >Salvar</button>
                  <span class="ui button button__default">
                    <router-link :to="'/backoffice/users/'">Cancelar</router-link>
                  </span>
                  <span class="ui button right floated delete" @click="openModal">Excluir usuário</span>
                </form>

                <div class="ui message" v-if="box.status" v-bind:class="[box.status]">
                  <div class="header">{{box.message.header}}</div>
                  <p>{{box.message.content}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ui mini modal" v-if="modal" :class="{active:modal}">
          <div class="header">Excluir usuário</div>
          <div class="content">
            <p>Deseja realmente excluir o usuário?</p>
          </div>
          <div class="actions">
            <div class="ui negative button" v-on:click="closeModal">Cancelar</div>
            <div class="ui positive right labeled icon button" v-on:click="deleteUser">
              Excluir
              <i class="checkmark icon"></i>
            </div>
          </div>
        </div>
        <div class="overlay" v-if="modal" v-on:click="closeModal"></div>
      </main>
    </div>
  </Backoffice>
</template>

<script>
import Backoffice from "../../Template/Backoffice";
import { emailToken } from "../../../utils/index";

export default {
  name: "Edit-Users",
  components: {
    Backoffice,
  },
  watch: {},
  data() {
    return {
      loading: false,
      users: [],
      password: "",
      message: {
        sendForm: false,
        passwordMessage: false,
      },
      modal: false,
      box: {
        status: "",
        message: {
          header: "",
          content: "",
        },
      },
    };
  },
  computed: {},
  methods: {
    getUserList() {
      let vm = this;
      vm.loading = true;

      let id = this.$route.params.id;

      vm.$store.dispatch("User/GET_ADMIN_USER_BY_ID", id).then((_response) => {
        let { id, username, email, firstName, lastName } = _response.list[0];

        let user = [
          {
            id,
            username,
            email,
            firstName,
            lastName,
          },
        ];

        vm.verifyIsUser(user);
        vm.users = user;
        vm.loading = false;
      });
    },
    verifyIsUser(user) {
      let { email } = user[0];
      let emailStorage = emailToken(localStorage.token);
      emailStorage !== email && this.$router.push({ name: "backoffice users" });
    },
    submitUpdate() {
      let vm = this;
      vm.message.sendForm = true;
      let form = Object.assign(vm.users[0], { password: vm.password });

      form.password == "" && delete form.password;
      let email = emailToken(localStorage.token);

      if (email == vm.users[0].email) {
        let data = JSON.stringify(form);
        vm.$store.dispatch("User/UPDATE_ADMIN_USER", data).then(() => {
          vm.message.sendForm = false;
          vm.userUpdateMessageSuccess();
        });
      } else {
        vm.message.sendForm = false;
        vm.userUpdateMessageFail();
      }
    },
    userUpdateMessageSuccess() {
      let vm = this;
      vm.box.status = "success";
      vm.box.message.header = "Usuário Atualizado!";
      vm.box.message.content = "Usuário foi atualizado com sucesso.";
      vm.closeBox();
    },
    userUpdateMessageFail() {
      let vm = this;
      vm.box.status = "error";
      vm.box.message.header = "Não foi possível!";
      vm.box.message.content =
        "Você não possui permissões suficientes para realizar as alterações.";
      vm.closeBox();
    },
    removeUserMessageSuccess() {
      let vm = this;
      vm.box.status = "success";
      vm.box.message.header = "Usuário foi removido!";
      vm.box.message.content = "Usuário foi removido com sucesso.";
      vm.closeBox();
    },

    deleteUser() {
      let vm = this;
      let id = { id: vm.users[0].id };
      let email = emailToken(localStorage.token);

      if (email == vm.users[0].email) {
        vm.$store.dispatch("User/DELETE_ADMIN_USER", id).then(() => {
          vm.message.sendForm = false;
          vm.removeUserMessageSuccess();
          vm.modal = false;
          setTimeout(() => {
            this.$router.push({ name: "backoffice users" });
          }, 2000);
        });
      } else {
        vm.modal = false;
        vm.message.sendForm = false;
        vm.userUpdateMessageFail();
      }
    },

    closeBox() {
      let vm = this;
      setTimeout(() => {
        vm.box.status = "";
      }, 6000);
    },
    openModal() {
      let vm = this;
      vm.modal = true;
    },
    closeModal() {
      let vm = this;
      vm.modal = false;
    },
  },
  beforeCreate() {},
  created() {
    let vm = this;
    vm.getUserList();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/globals/buttons";
h2 {
  font-family: Barlow;
  font-size: 16.5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  text-align: right;
  color: #757575;
  margin: 0;
  padding-left: 0.5rem;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  background: #000;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  opacity: 0.4;
}

.button__default {
  a {
    color: $color-primary;
  }
}
</style>
